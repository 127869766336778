
import {Options, Vue} from "vue-class-component";

@Options({
	props: {
		stepIndex: Number,
		stepsConf: Object
	},
	watch: {
		stepIndex(val) {
			// if (val > this.activeStep) this.stepsConf[this.activeStep].done = true
			this.activeStep = val
		},
		stepsConf(val) {
			this.steps = val
		}
	}
})
export default class StepWizard extends Vue {
	activeStep = 0
	steps: any = {}
	stepsConf: any
	stepIndex: any
	
	go(stepIndex: any) {
		/*
		const stepKeys: any = Object.keys(this.steps)
		const index = stepKeys.indexOf(this.activeStep.toString())
		
		const newStep = stepKeys[index + 1]
		this.activeStep = parseInt(newStep)
		*/
		this.$emit('step', { stepIndex })
	}
	
	mounted() {
		this.steps = this.stepsConf
		this.activeStep = this.stepIndex
	}
}
