import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "wizard-form steps-basic wizard clearfix" }
const _hoisted_2 = { class: "steps steps-container" }
const _hoisted_3 = { role: "tablist" }
const _hoisted_4 = ["aria-disabled"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, stepIndex) => {
          return (_openBlock(), _createElementBlock("li", {
            key: stepIndex,
            role: "tab",
            class: _normalizeClass([{'current':stepIndex==_ctx.activeStep}, {'done':step.done&&stepIndex!=_ctx.activeStep, 'disabled':!step.done}]),
            "aria-disabled": !step.done
          }, [
            _createElementVNode("a", {
              href: "",
              class: _normalizeClass({'disabled':!step.done}),
              onClick: _withModifiers(($event: any) => (_ctx.go(stepIndex)), ["prevent"])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["number", step.icon])
              }, _toDisplayString(step.icon ? null : stepIndex), 3),
              _createTextVNode(" " + _toDisplayString(step.name), 1)
            ], 10, _hoisted_5)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}