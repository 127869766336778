
import {Options, Vue} from "vue-class-component";
import StepWizard from "@/components/StepWizard.vue";
import {
	getInterventionTypesRequest,
	getProjectInterventionTypesRequest,
	saveSystemInterventionContactsRequest
} from "@/services/InterventionService";
import {getEmployees} from "@/services/EmplyeeService";
import {getSystemsByInterventionTypeRequest} from "@/services/ObjectService";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import Interventions from "@/views/basic/Intervention/Interventions.vue";
import {hideLoadingLayer, showLoadingLayer, stringToDate} from "@/services/Globals";

interface SystemContacts {
	id: number | string
	name: string
	availableContacts: any[]
	contactOrder: any[]
	icon?: string
	number: number | string
	date: string
}

@Options({
	components: {Interventions, StepWizard, draggable }
})
export default class ContactConfigProcess extends Vue {
	interventionTypeConfig: any = {}
	interventionTypes: any = []
	activeStep = 80
	steps: any = {}
	systemContactsByIntervention: any = {}
	contactsByIntervention: any = {}
	availableContacts: any[] = []
	systemsConfig: any = {}
	
	get objectId() {
		return this.$route.params.id
	}
	
	get type(): any {
		return this.$route.params.type || null
	}
	
	stringToDate(datestring: any) {
		return stringToDate(datestring, 'de')
	}
	
	step(data: any) {
		const stepKeys: any = Object.keys(this.steps)
		const beforeIndex: any = stepKeys.indexOf(this.activeStep)
		const afterIndex = stepKeys.indexOf(data.stepIndex)
		
		if ( afterIndex > beforeIndex ) {
			this.steps[this.activeStep].done = true
		}
		
		this.activeStep = parseInt(data.stepIndex)
		
		const wrapperElem: any = document.querySelector('.wrapper')
		wrapperElem.scroll({top: 0, left: 0, behavior: 'smooth'});
	}
	
	next() {
		this.steps[this.activeStep].done = true
		
		const stepKeys: any = Object.keys(this.steps)
		const index = stepKeys.indexOf(this.activeStep.toString())
		
		const newType = stepKeys[index + 1]
		this.activeStep = parseInt(newType)
		
		const wrapperElem: any = document.querySelector('.wrapper')
		wrapperElem.scroll({top: 0, left: 0, behavior: 'smooth'});
	}
	
	checkForAllSystems(elem: any) {console.log(elem)
		const index = this.contactsByIntervention[this.activeStep].availableContacts.indexOf(elem)
		const splicedElem = this.contactsByIntervention[this.activeStep].availableContacts.splice(index, 1)[0]
		this.contactsByIntervention[this.activeStep].contactOrder.push(splicedElem)
	}
	
	removeForAllSystems(elem: any) {
		const index = this.contactsByIntervention[this.activeStep].contactOrder.indexOf(elem)
		const splicedElem = this.contactsByIntervention[this.activeStep].contactOrder.splice(index, 1)[0]
		this.contactsByIntervention[this.activeStep].availableContacts.push(splicedElem)
	}
	
	checkForSystem(elem: any, system: any) {
		const systemIndex = this.systemContactsByIntervention[this.activeStep].indexOf(system)
		// const filteredSystem = this.systemContactsByIntervention[this.activeStep][systemIndex]
		const index = this.systemContactsByIntervention[this.activeStep][systemIndex].availableContacts.indexOf(elem)
		const splicedElem = this.systemContactsByIntervention[this.activeStep][systemIndex].availableContacts.splice(index, 1)[0]
		this.systemContactsByIntervention[this.activeStep][systemIndex].contactOrder.push(splicedElem)
	}
	
	removeForSystem(elem: any, system: any) {
		const systemIndex = this.systemContactsByIntervention[this.activeStep].indexOf(system)
		// const filteredSystem = this.systemContactsByIntervention[this.activeStep][systemIndex]
		const index = this.systemContactsByIntervention[this.activeStep][systemIndex].contactOrder.indexOf(elem)
		const splicedElem = this.systemContactsByIntervention[this.activeStep][systemIndex].contactOrder.splice(index, 1)[0]
		this.systemContactsByIntervention[this.activeStep][systemIndex].availableContacts.push(splicedElem)
	}
	
	async save() {
		let error = null
		const data: any = {}
		
		data.objectId = this.objectId
		
		await Promise.all(this.interventionTypes.map(async (intType: any) => {
			data.interventionType = intType
			const config = this.systemsConfig[intType]
			
			if ( config === 'all' ) {
				const systems: any = []
				this.systemContactsByIntervention[intType].map((system: any) => {
					systems.push(system.id)
				})
				data.systemId = systems
				
				const contacts: any = []
				this.contactsByIntervention[intType].contactOrder.map((contact: any) => {
					contacts.push(contact.id)
				})
				data.contacts = contacts
				
				await saveSystemInterventionContactsRequest(data).then((res: any) => {
					if ( res.status === "OK" ) {
						error = false
					} else {
						error = true
					}
				})
				
			} else {
				if ( !this.contactsByIntervention[intType] || this.contactsByIntervention[intType].length <= 0 ) return false
				
				await Promise.all(this.systemContactsByIntervention[intType].map(async (system: any) => {
					data.systemId = system.id
					
					const contacts: any = []
					system.contactOrder.map((contact: any) => {
						contacts.push(contact.id)
					})
					data.contacts = contacts
					
					await saveSystemInterventionContactsRequest(data).then((res: any) => {
						if ( res.status === "OK" ) {
							error = false
						} else {
							error = true
						}
					})
				}))
				
			}
			
			
		}))
		
		// if all calls are done
		if ( !error ) {
			Swal.fire({
				title: "Erfolgreich",
				text: "Ihre Kontakte wurden erfolgreich zugewiesen",
				buttonsStyling: false,
				customClass: {
					confirmButton: "btn btn-primary"
				}
			}).then(() => {
				this.$router.push({ name: 'InterventionsAllOrIndividual' })
			})
		}

	}
	
	async beforeMount() {
		showLoadingLayer()
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypeConfig = res.result
		})
		
		const requestData = {
			objectId: this.objectId,
			filter: { verification: [1] }
		}
		
		await getEmployees(requestData).then((res: any ) => {
			if ( res.status === "OK" ) {
				this.availableContacts = res.result
			}
		})
		
		if ( !this.type) {
			await getProjectInterventionTypesRequest(this.objectId).then((res: any) => {
				if ( res.status === 'OK' ) {
					this.interventionTypes = res.result
					
					res.result.map((item: any) => {
						this.steps[item] = { name: this.interventionTypeConfig[item].name, icon: this.interventionTypeConfig[item].icon, done: false }
						this.systemContactsByIntervention[item] = []
						this.contactsByIntervention[item] = { availableContacts: [], contactOrder: [] }
						this.systemsConfig[item] = 'all'
					})
					
					this.activeStep = parseInt(Object.keys(this.steps)[0])
				}
			})
		} else {
			this.interventionTypes.push(this.type)
			this.steps[this.type] = { name: this.interventionTypeConfig[this.type].name, icon: this.interventionTypeConfig[this.type].icon, done: false }
			this.systemContactsByIntervention[this.type] = []
			this.contactsByIntervention[this.type] = { availableContacts: [], contactOrder: [] }
			this.systemsConfig[this.type] = 'all'
			
			this.activeStep = parseInt(Object.keys(this.steps)[0])
		}
		
		
		
		this.interventionTypes.map((type: any) => {
			 getSystemsByInterventionTypeRequest(type, this.objectId).then((res: any) => {
				if ( res.status === "OK" ) {
					
					if ( res.result.length <= 1 ) this.systemsConfig[type] = 'individual'
					
					res.result.map((item: any) => {
						const availableContacts: any[] = []
						this.availableContacts.map((item: any) => {
							const contact: any = {
								id: item.id,
								firstName: item.firstName,
								lastName: item.lastName,
								tel: item.tel
							}
							availableContacts.push(contact)
						})
						
						const system: SystemContacts = {
							id: item.anlage_id,
							name: item.anlage_bemerkung,
							icon: item.icon,
							number: item.anlage_nummer,
							date: item.anlage_einbau,
							contactOrder: [],
							availableContacts: availableContacts
						}
						
						this.systemContactsByIntervention[type].push(system)
						this.contactsByIntervention[type].availableContacts = availableContacts
					})
				}
				
				hideLoadingLayer()
			})
		})
	}
	
}
