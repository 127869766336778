
import {Options, Vue} from "vue-class-component";
import {
	deleteInterventionRequest,
	getInterventionTypesRequest,
	getSystemInterventionsRequest
} from "@/services/InterventionService";
import {hideLoadingLayer, showLoadingLayer, validateWeekday} from "@/services/Globals";
import {_timeRanges} from "@/services/Globals";

@Options({
	props: {
		interventionType: {
			type: Number
		}
	}
})
export default class Interventions extends Vue {
	interventionTypes: any = {}
	interventions: any = {}
	interventionType: any
	timeRanges: any = _timeRanges
	
	get headline() {
		// const interventionName = this.interventionName
		const headline = this.$route.meta.headline
		return `Interventionen im Fall ${ headline }`
	}
	
	get interventionName() {
		return this.interventionTypes[this.interventionType]?.name
	}
	
	get systemId(): any {
		return this.$route.params.system
	}
	
	get user() {
		return this.$store.getters.user
	}

	newIntervention(type: any) {
		this.$router.push({ name: 'ObjectSystemInterventionNew', params: { type } })
	}
	
	deleteIntervention(item: any) {
		const index = this.interventions[this.interventionType].items.indexOf(item)
		
		deleteInterventionRequest(item.id, this.interventionType, this.systemId).then((res: any) => {
			if ( res.result ) {
				this.interventions[this.interventionType].items.splice(index, 1)
			}
		})
	}

	shouldDisableInterventionChanges() {
		if (!this.user.rights.interventions) {
			return true;
		}
		if (this.user.rights.interventions) {
			return this.user.rights.interventions.write
		}
	}
	
	takeOver() {
		this.$router.push({ name: 'InterventionTakeOver', params: { type: this.interventionType } })
	}
	
	editIntervention(id: any) {
		// kuchen
		this.$router.push({ name: 'ObjectSystemInterventionDetails', params: { intervention: id, type: this.interventionType } })
	}
	
	beforeMount() {
		// get all interventionTypes
		getInterventionTypesRequest().then((res: any) => {
			this.interventionTypes = res.result
		})
		
	}
	
	async mounted() {
		showLoadingLayer()
		
		const interventionType = this.interventionType
		
		// get all Interventions
		await getSystemInterventionsRequest(this.systemId).then((res: any) => {
			if ( res.result ) {
				const intKeys = Object.keys(res.result)
				intKeys.map((intervention: any) => {
					intervention = parseInt(intervention)
					
					if ( res.result[intervention].items.length > 0 && intervention === interventionType) {
						res.result[intervention].items.sort((a: any, b: any) => a.timeStart - b.timeStart)
						res.result[intervention].items.map((item: any) => {
							const validateTime = validateWeekday(item.timeStart, item.timeEnd)
							item.timeStart = validateTime.start.hours+':'+validateTime.start.minutes
							item.timeEnd = validateTime.end.hours+':'+validateTime.end.minutes
							item.weekday = validateTime.weekday
						})
					} else if ( res.result[intervention].items.length === 0 && intervention === interventionType ) {
						//
					} else {
						delete res.result[intervention]
					}
				})
				this.interventions = res.result
			}
			
			hideLoadingLayer()
		})
	}
}
